import { Button } from '@finn/design-system/atoms/button';
import { CTA } from '@finn/ui-components';
import { CTAData } from '@finn/ui-cosmic';
import { parseToHtml } from '@finn/ui-utils';

type Textdata = {
  title: string;
  description: string;
  button_href: string;
  button_text: string;
  cta?: CTAData;
};
type Props = {
  data: Textdata;
};

const FeaturedVehicleText = ({ data }: Props) => {
  const { title, description, button_href, button_text, cta } = data;

  return (
    <div className="mb-12 md:mb-0">
      <h2 className="mobile-t2-semibold md:web-t2-semibold mb-6">
        {parseToHtml(title)}
      </h2>
      <p className="body-16-light mb-6">{parseToHtml(description)}</p>
      {cta ? (
        <CTA data={cta.metadata} />
      ) : (
        <Button href={button_href}>{button_text}</Button>
      )}
    </div>
  );
};
export default FeaturedVehicleText;
